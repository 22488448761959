<template>
  <div class="list-purchase-order-page">
    <KTCodePreview v-bind:title="'Danh sách phiếu yêu cầu nhập hàng'">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown
            v-if="checkPermission('PO_UPDATE')"
            size="sm"
            variant="warning"
            right
            :disabled="selectedPurchaseOrders.length ? false : true"
          >
            <template slot="button-content">
              <span class="font-weight-bolder">
                <span class="count-status badge badge-circle badge-white mr-1">{{ selectedPurchaseOrders.length }}</span>
                Đã chọn
              </span>
            </template>
            <b-dropdown-item @click="onShowSentPOSamsungModal()">
              <i class="fa fa-file-export"></i>
              &nbsp; Đẩy lệnh đặt hàng Samsung
            </b-dropdown-item>
          </b-dropdown>
          <div v-if="false">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-if="checkPermission('PO_INSERT')"
            class="ml-3"
          >
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="onClickCreate('info-purchase-orders')"
              class="ml-4"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
              <b-dropdown-item
                @click="onClickCreate('share-purchase-orders')"
                v-if="!disableStore"
              >
                <i class="far fa-file-excel mr-2"></i>
                <span>PO chia hàng</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="search-col">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedType"
              :options="listType"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
          <b-col>
            <Autosuggest
              class="border-radius-none"
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="sản phẩm"
              :limit="20"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedRelationType"
              :options="listRelationType"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="search"
              type="text"
              placeholder="Tìm kiếm nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <Autosuggest
              class="border-radius-none"
              :model="searchStore"
              :suggestions="filteredOptionStore"
              placeholder="kho"
              :limit="200"
              @select="onSelectedStore"
              @change="onInputChangeStore"
              suggestionName="suggestionName"
              :disabled="disableStore"
            />
          </b-col>
          <b-col>
            <Autosuggest
              class="border-radius-none"
              :model="searchProvider"
              :suggestions="filteredOptionsProvider"
              placeholder="nhà cung cấp"
              :limit="200"
              @select="onSelectedProvider"
              @change="onInputChangeProvider"
              suggestionName="suggestionName"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedImportStatus"
              :options="listImportStatus"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
          <b-col md="3">
            <Autosuggest
              class="border-radius-none"
              :model="searchEmployee"
              :suggestions="filteredEmployeeOptions"
              placeholder="người tạo"
              :limit="200"
              @select="onSelectedEmployee"
              @change="onSearchEmployeeChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedSentStatus"
              :options="listSentStatus"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedSourceType"
              :disabled="disableSourceType"
              :options="listSourceType"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              :disabled="onLoading"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <b-nav
          class="my-3"
          tabs
          pills
        >
          <b-nav-item
            v-for="item in listStatus"
            :key="item.id"
            @click="handleFilterByStatus(item)"
            :active="item.id === selectedStatus"
          >
            {{ item.name }}
          </b-nav-item>
        </b-nav>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listPurchaseOrder"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template slot="head(checkbox)">
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAllRows"
            ></b-form-checkbox>
          </template>
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>
          <template #cell(checkbox)="row">
            <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
          </template>
          <template #header(checkbox)>
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAll"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(code)="row">
            <div style="
                justify-content: center;
                align-items: center;
                display: grid;
              ">
              <a
                class="font-weight-bold"
                @click="onShowPOInfoModal(row.item.code)"
                v-if="row.item.relationType === PO_RELATION_TYPE.CHILD"
                style="cursor: pointer"
              >
                {{ row.item.code }}
              </a>
              <a
                class="font-weight-bold"
                v-bind:href="getLinkDoc(row.item)"
                v-else
              >
                {{ row.item.code }}
              </a>
              <br />
              <p>{{ row.item.createdAt }}</p>
              <p>{{ row.item.createdByName }}</p>
              <div
                style="cursor: pointer"
                class="d-flex justify-content-around align-items-center"
              >
                <i
                  v-b-tooltip.hover
                  :title="`Nguồn ${row.item.sourceName}`"
                  :class="sourceTypeClasses(row.item.sourceType)"
                ></i>
                <v-icon
                  small
                  v-b-tooltip
                  :title="row.item.relationTypeName"
                >{{
                  checkRelationTypeOfPO(row.item)
                }}</v-icon>
              </div>
            </div>
          </template>
          <template v-slot:cell(providerName)="row">
            <span v-text="row.item.providerName"></span>
            <div>
              <p class="text-success">
                {{ row.item.typeName }}
              </p>
            </div>
          </template>
          <template v-slot:cell(statusName)="row">
            <div>
              <span
                v-text="row.item.statusName"
                class="label font-weight-bold label-lg label-inline mb-2"
                :class="statusNameClasses(row.item.status)"
                style="width: max-content"
              ></span>
              <div v-if="
                  row.item.status === PURCHASE_ORDER_STATUS.PURCHASE ||
                  row.item.status === PURCHASE_ORDER_STATUS.ACCOUNTANT
                ">
                <p class="text-bold">
                  Duyệt lúc:
                  <i
                    v-b-tooltip.hover
                    :title="row.item.purchaseApproveByName"
                    class="fas fa-user-edit"
                  ></i>
                </p>
                <p class="text-date-po">{{ row.item.purchaseApproveDate }}</p>
              </div>
              <div v-if="row.item.status === PURCHASE_ORDER_STATUS.ACCOUNTANT">
                <p class="text-bold">
                  Xác nhận:<i
                    v-b-tooltip.hover
                    :title="row.item.accountantApproveByName"
                    class="fas fa-user-check"
                  ></i>
                </p>
                <p class="text-date-po">{{ row.item.accountantApproveDate }}</p>
              </div>
            </div>
          </template>
          <template v-slot:cell(sentStatusName)="row">
            <span
              v-text="row.item.sentStatusName"
              class="label font-weight-bold label-lg label-inline"
              :class="statusNameClasses(row.item.sentStatus)"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(storeName)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.storeName"></span>
            </div>
          </template>
          <template v-slot:cell(description)="row">
            <div
              @mouseover="hoverNote = true"
              @mouseleave="hoverNote = false"
            >
              <span
                v-text="row.item.importReason"
                class="mr-2"
              ></span>
              <p v-if="row.item.description && row.item.importReason">
                --------------
              </p>
              <span
                v-text="row.item.description"
                class="mr-2"
              ></span>
              <span @click="handleShowNoteModel(row.item)">
                <i
                  v-if="hoverNote"
                  class="fas fa-edit"
                ></i>
              </span>
            </div>
          </template>
          <template v-slot:cell(payment)="row">
            <p class="text-primary">
              Tổng tiền: {{ convertPrice(row.item.totalAmount) }}
            </p>
            <p
              class="border-0"
              v-if="row.item.paymentAmount > 0"
            >
              <i
                v-b-tooltip.hover
                :title="'Đã thanh toán'"
                class="fas fa-money-bill-alt icon-color"
              ></i>
              : {{ convertPrice(row.item.paymentAmount) }}
            </p>
            <p
              class="text-danger"
              v-else
            >Chưa thanh toán</p>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span class="dropdown-item-text">
                    <i class="flaticon2-printer"></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="exportExcel(row.item.id)"
                  v-if="row.item.relationType !== PO_RELATION_TYPE.PARENT"
                >
                  <span class="dropdown-item-text">
                    <i class="far fa-file-excel"></i>
                    &nbsp; Xuất excel
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="copyItem(row.item)"
                  v-if="row.item.relationType !== PO_RELATION_TYPE.PARENT"
                >
                  <span class="dropdown-item-text">
                    <i class="far fa-copy"></i>
                    &nbsp; Sao chép
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="row.item.relationType === PO_RELATION_TYPE.CHILD"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkEdit(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="requestForApprove(row.item)"
                  v-if="isCanRequestApprovePO(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="fa fa-paper-plane"></i>
                    &nbsp; Yêu cầu duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="approvePO(row.item)"
                  v-if="isCanApprovePO(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="fa fa-angle-double-right"></i>
                    &nbsp; Duyệt yêu cầu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="approvePO(row.item)"
                  v-if="isCanConfirmPO(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="fa fa-angle-double-right"></i>
                    &nbsp; Xác nhận nhập hàng
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addStockSlip(row.item)"
                  v-if="checkCreateStockSlip(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon2-add-1"></i>
                    &nbsp; Tạo phiếu nhập
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addExportStockSlip(row.item)"
                  v-if="row.item.totalImportQuantity"
                >
                  <span class="dropdown-item-text">
                    <i class="fas fa-file-export"></i>
                    &nbsp; Tạo phiếu xuất trả
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showModalCancel(row.item)"
                  v-if="checkCancel(row.item) && checkPermission('PO_CANCEL')"
                >
                  <span class="dropdown-item-text">
                    <i class="fas fa-ban"></i>
                    &nbsp; Trả phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item, 'cancel-request')"
                  v-if="isCanAccountantApprove(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="fas fa-ban text-danger"></i>
                    &nbsp; Hủy phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkSentPOValid(row.item)"
                  @click="handleSendingPurchaseOrder(row.item)"
                >
                  <span class="dropdown-item-text">
                    <i class="fa fa-file-export"></i>
                    &nbsp; Đẩy lệnh
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('PO_DELETE')"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon2-rubbish-bin-delete-button"></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages || 1"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditNoteModal"
            hide-footer
            :title="
              'Sửa ghi chú/ lý do nhập hàng cho phiếu: ' + purchaseOrderCode
            "
          >
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Lý do nhập hàng</label>
                <b-form-textarea
                  v-model="importNote"
                  placeholder="Nhập lý do nhập hàng..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Ghi chú</label>
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                >Lưu</b-button>
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                >Hủy</b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>
        <CancelPO
          ref="po-cancel"
          :purchaseOrderCode="purchaseOrderCode"
          :purchaseOrderId="purchaseOrderId"
          v-on:onSuccess="fetchData"
        />
        <POInfo ref="purchase-order-info-modal" />
        <POPushModal
          :selectedPurchaseOrders="selectedPurchaseOrdersSamsung"
          :modalOpen="modalOpen"
          @fetch-data="fetchData"
          @modal-hidden="modalHidden"
        ></POPushModal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';
import {
  PURCHASE_ORDER_STATUS,
  PO_RELATION_TYPE,
  PURCHASE_ORDER_SOURCE,
  JOB_TITLE_SHOW_BY_OWNER,
  JOB_TITLE_EMLOYEE_STORE,
  JOB_TITLE,
  PO_SENT_STATUS,
} from '@/utils/enum';
import { omitBy, isNull, omit } from 'lodash';
import CancelPO from '../../components/stock-slips/CancelPO';
import {
  PURCHASE_ORDER_TYPE,
  BASE_URL,
  TIME_TRIGGER,
} from './../../../utils/constants';
import fileDownload from '../../../utils/file-download';
import axios from 'axios';
import 'vue-cute-timeline/dist/index.css';
import {
  xoa_dau,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  getEmployeeFromLocal,
} from '@/utils/common';
import POInfo from '../../components/purchase-orders/POInfo';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import POPushModal from '@/view/components/purchase-orders/POPushModal.vue';

export default {
  data() {
    return {
      modalOpen: false,
      allSelected: false,
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      listPurchaseOrder: [],
      search: '',
      searchStore: '',
      searchId: '',
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      fields: [
        {
          key: 'checkbox',
          label: '',
          sortable: false,
          thStyle: { width: '4%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '16%' },
        },
        {
          key: 'storeName',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'providerName',
          label: 'NCC',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '14%' },
        },
        {
          key: 'totalProductCount',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalProductQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalImportQuantity',
          label: 'SL đã nhập',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
        },
        {
          key: 'sentStatusName',
          label: 'Trạng thái gửi',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
        },
        {
          key: 'payment',
          label: 'Thanh toán',
          thStyle: { fontWeight: 600, color: '#181c32', width: '14%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selectedStoreId: null,
      optionsStore: [],
      filteredOptionStore: [],
      limit: 10,
      searchProvider: '',
      selectedProvider: null,
      optionsProvider: [],
      filteredOptionsProvider: [],
      searchProduct: '',
      selectedStatus: null,
      selectedSourceType: null,
      selectedSentStatus: null,
      listStatus: [
        {
          id: null,
          name: 'Tất cả trạng thái',
        },
        {
          id: PURCHASE_ORDER_STATUS.NEW,
          name: 'Mới',
        },
        {
          id: PURCHASE_ORDER_STATUS.READY,
          name: 'Yêu cầu duyệt',
        },
        {
          id: PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL,
          name: 'Trả phiếu duyệt',
        },
        {
          id: PURCHASE_ORDER_STATUS.PURCHASE,
          name: 'Đã duyệt',
        },
        {
          id: PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL,
          name: 'Trả phiếu xác nhận',
        },
        {
          id: PURCHASE_ORDER_STATUS.ACCOUNTANT,
          name: 'Đã xác nhận',
        },
      ],
      listSentStatus: [
        {
          id: null,
          name: 'Trạng thái gửi',
        },
        {
          id: PO_SENT_STATUS.NEW,
          name: 'Chưa gửi',
        },
        {
          id: PO_SENT_STATUS.SENT_FAIL,
          name: 'Gửi không thành công',
        },
        {
          id: PO_SENT_STATUS.SENT_SUCCESS,
          name: 'Gửi thành công',
        },
      ],
      listSourceType: [
        {
          id: null,
          name: 'Tất cả nguồn ',
        },
        {
          id: PURCHASE_ORDER_SOURCE.PURCHASE,
          name: 'Nguồn ngành hàng',
        },
        {
          id: PURCHASE_ORDER_SOURCE.STORE,
          name: 'Nguồn cửa hàng',
        },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [],
      hoverNote: false,
      purchaseOrderCode: '',
      showEditNoteModal: false,
      note: '',
      importNote: '',
      userStoreId: null,
      btnCreate: {
        fontWeight: '600!important',
      },
      PURCHASE_ORDER_STATUS: PURCHASE_ORDER_STATUS,
      PO_SENT_STATUS: PO_SENT_STATUS,
      purchaseOrderId: null,
      selectedType: null,
      listType: PURCHASE_ORDER_TYPE,
      selectedEmployeeId: null,
      optionsEmployee: [],
      filteredEmployeeOptions: [],
      searchEmployee: '',
      selectedImportStatus: null,
      listImportStatus: [
        {
          id: null,
          name: 'Trạng thái nhập kho',
        },
        {
          id: 1,
          name: 'Nhập đủ',
        },
        {
          id: 2,
          name: 'Nhập thiếu',
        },
        {
          id: 3,
          name: 'Nhập dư',
        },
        {
          id: 4,
          name: 'Chưa nhập hàng',
        },
      ],
      selectedCompany: null,
      listCompany: [],
      PO_RELATION_TYPE: PO_RELATION_TYPE,
      selectedRelationType: null,
      listRelationType: [
        {
          id: null,
          name: 'Loại phiếu nhập',
        },
        {
          id: 1,
          name: 'PO độc lập',
        },
        {
          id: 2,
          name: 'PO tổng',
        },
        {
          id: 3,
          name: 'PO con',
        },
      ],
      disableStore: false,
      employInfo: null,
      disableSourceType: false,
      selectedPurchaseOrdersSamsung: [],
      selectedProductId: null,
    };
  },
  methods: {
    handleSendingPurchaseOrder(purchaseOrder) {
      const isValid = this.checkSentPOValid(purchaseOrder);
      if (!isValid) {
        return makeToastFaile('Phiếu nhập hàng không hợp lệ! ');
      }
      this.selectedPurchaseOrdersSamsung = [purchaseOrder];
      this.modalOpen = true;
    },
    selectAllRows() {
      this.listPurchaseOrder = this.listPurchaseOrder.map((element) => {
        return {
          ...element,
          selected: this.allSelected,
        };
      });
    },
    onShowSentPOSamsungModal() {
      this.selectedPurchaseOrdersSamsung = this.selectedPurchaseOrders.filter(
        (element) => {
          return this.checkSentPOValid(element);
        },
      );
      this.modalOpen = true;
    },
    sourceTypeClasses(sourceType) {
      const classObject = {
        [PURCHASE_ORDER_SOURCE.STORE]: 'text-success fa fa-store',
        [PURCHASE_ORDER_SOURCE.PURCHASE]: 'text-warning fa fa-archive',
      };
      return classObject[sourceType];
    },
    checkSentPOValid(POItem) {
      if (POItem.status !== PURCHASE_ORDER_STATUS.ACCOUNTANT) {
        return false;
      }
      if (POItem.sentStatus === PO_SENT_STATUS.SENT_SUCCESS) {
        return false;
      }
      return true;
    },
    modalHidden() {
      this.modalOpen = false;
    },
    statusNameClasses(statusId) {
      const statusClassMapping = {
        [PURCHASE_ORDER_STATUS.NEW]: 'label-light-warning',
        [PURCHASE_ORDER_STATUS.PURCHASE]: 'label-light-info',
        [PURCHASE_ORDER_STATUS.ACCOUNTANT]: 'label-light-success',
        [PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL]: 'label-light-danger',
        [PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCE]: 'label-light-dark',
        [PURCHASE_ORDER_STATUS.READY]: 'label-light-default',
      };
      return statusClassMapping[statusId] || 'label-light-dark';
    },
    convertPrice,
    fetchData: async function () {
      if (this.onLoading) return;
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        pageNumber: this.page,
        pageSize: 10,
        description: this.search ? this.search.trim() : '',
        code: this.searchId ? this.searchId.trim() : null,
        status: this.selectedStatus,
        sentStatus: this.selectedSentStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        storeId: this.selectedStoreId,
        providerId: this.selectedProvider,
        productInfo: this.searchProduct,
        type: this.selectedType,
        createdById: this.selectedEmployeeId,
        importStatus: this.selectedImportStatus || null,
        companyId: this.selectedCompany,
        relationType: this.selectedRelationType
          ? [this.selectedRelationType]
          : null,
        ...this.setParamFilter(),
      };
      ApiService.query('purchase-order/v2', { params })
        .then((response) => {
          const responseData = response.data.data;
          this.totalPages = responseData.totalPage || 1;
          this.totalItems = responseData.total;
          this.listPurchaseOrder = responseData.data.map((item, index) => {
            return {
              ...item,
              count: index + 1,
              createdBy: item.createdByName ? item.createdByName : '',
              createdAt: moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
              purchaseApproveByName: item.purchaseApproveByName
                ? item.purchaseApproveByName
                : '',
              purchaseApproveDate: item.purchaseApproveDate
                ? moment(item.purchaseApproveDate).format('DD/MM/YYYY HH:mm:ss')
                : '',
              accountantApproveByName: item.accountantApproveByName
                ? item.accountantApproveByName
                : '',
              accountantApproveDate: item.accountantApproveDate
                ? moment(item.accountantApproveDate).format(
                    'DD/MM/YYYY HH:mm:ss',
                  )
                : '',
              selected: false,
            };
          });
        })
        .catch(({ response }) => {
          const message = response.data?.message || 'Network error';
          makeToastFaile(message);
        })
        .finally(() => {
          this.onLoading = false;
        });
    },
    editItem: function (item) {
      if (item.relationType === PO_RELATION_TYPE.CHILD) {
        this.onShowPOInfoModal(item.code);
        return;
      }
      const pathName =
        item.relationType === PO_RELATION_TYPE.PARENT
          ? 'share-purchase-orders'
          : 'update-purchase-orders';
      this.$router.push({
        name: pathName,
        query: { id: item.id },
      });
    },
    onSelectedStore(option) {
      this.selectedStoreId = option.item.id;
      this.searchStore = option.item.name;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionStore = [...filteredData];
    },
    showDeleteAlert: function (item, action = '') {
      const title = action
        ? 'Hủy yêu cầu duyệt nhập hàng!'
        : 'Xóa phiếu yêu cầu nhập hàng!';
      const text = action
        ? 'Bạn có chắc muốn hủy yêu cầu duyệt nhập hàng này?'
        : 'Bạn có chắc muốn xóa phiếu yêu cầu nhập hàng này?';
      const confirmText = action ? 'Đồng ý' : 'Xóa';
      const optionsNotify = {
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: confirmText,
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      };

      Swal.fire(optionsNotify).then((result) => {
        if (result.value) {
          action
            ? this.cancelRequestPurcharseOrder(item)
            : this.deleteItem(item);
        }
      });
    },
    cancelRequestPurcharseOrder: async function (item) {
      const url = `purchase-order/cancel-complete/${item.id}`;
      const method = 'put';
      ApiService[method](url)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.fetchData();
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message || 'Lỗi!';
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    deleteItem: async function (item) {
      ApiService.delete(`purchase-order/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          makeToastSuccess(data.message);
          this.fetchData();
        } else {
          makeToastFaile(data.message);
        }
      });
    },
    showModalCancel(item) {
      this.purchaseOrderId = item.id;
      this.purchaseOrderCode = item.code;
      this.$refs['po-cancel'].showModal();
    },
    approvePO: function (poItem) {
      const pathName =
        poItem.relationType === PO_RELATION_TYPE.PARENT
          ? 'approve-po-share'
          : 'approve-purchase-orders';
      const { href } = this.$router.resolve({
        name: pathName,
        query: { id: poItem.id },
      });
      window.open(href, '_blank');
    },
    requestForApprove: function (item) {
      const warningTitle =
        item.relationType === PO_RELATION_TYPE.PARENT
          ? 'Yêu cầu duyệt phiếu PO chia hàng!'
          : 'Yêu cầu duyệt phiếu yêu cầu nhập hàng!';
      const warningText =
        item.relationType === PO_RELATION_TYPE.PARENT
          ? 'Yêu cầu duyệt sẽ áp dụng cho các PO con. Bạn có chắc muốn yêu cầu duyệt phiếu yêu cầu nhập hàng này không ?'
          : 'Bạn có chắc muốn yêu cầu duyệt phiếu yêu cầu nhập hàng này không ?';
      Swal.fire({
        title: warningTitle,
        text: warningText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.requestForApproveItem(item.id);
        }
      });
    },
    requestForApproveItem(id) {
      ApiService.put(`purchase-order/request-for-approve/${id}`).then(
        ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchData();
          } else {
            makeToastFaile(data.message);
          }
        },
      );
    },
    viewTransferStock: function (item) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: item.relateStock },
      });
    },
    onSelectedProvider(option) {
      this.selectedProvider = option.item.id;
      this.searchProvider = option.item.name;
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      const filteredData = this.optionsProvider
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionsProvider = [...filteredData];
    },
    fetchProduct() {
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`,
      ).then(({ data }) => {
        const products = data.data;
        this.optionsProduct = products.map((element) => {
          return {
            ...element,
            suggestionName: element.productName,
          };
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
      this.selectedProductId = option.item.productId;
    },
    onInputChangeProduct(text) {
      this.searchProduct = text;
      if (!text) {
        this.selectedProductId = null;
      } else {
        this.debounceInput();
      }
    },
    onFilter() {
      this.$route.query.page = 1;
      const queryObj = omit(this.setParamFilter(), ['fromDate', 'toDate']);
      this.$router
        .replace({
          name: 'list-purchase-orders',
          query: {
            ...queryObj,
            page: this.$route.query.page,
            startDate: this.dpForm.startDate,
            endDate: this.dpForm.endDate,
          },
        })
        .catch(() => {});
      this.fetchData();
    },
    handleFilterByStatus(status) {
      this.selectedStatus = status.id;
      this.onFilter();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getLinkDoc(item) {
      if (item.relationType === PO_RELATION_TYPE.PARENT) {
        return `#/purchase-orders/share-purchase-orders?id=${item.id}`;
      }
      return `#/purchase-orders/update-purchase-orders?id=${item.id}`;
    },
    handleShowNoteModel(item) {
      this.showEditNoteModal = !this.showEditNoteModal;
      this.purchaseOrderCode = item.code;
      this.note = item.description;
      this.importNote = item.importReason;
    },
    updateNote() {
      const data = {
        code: this.purchaseOrderCode,
        description: this.note,
        importReason: this.importNote,
      };

      ApiService.put('purchase-order/update-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.showEditNoteModal = !this.showEditNoteModal;
            this.fetchData();
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-purchase-orders',
        query: { id: item.id },
      });
    },
    async fetchProvider() {
      await ApiService.get(`providers/getAll`).then(({ data }) => {
        this.optionsProvider = data.data.providers.map((element) => {
          return {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
        });
        const noneProvider = {
          id: null,
          name: 'Tất cả NCC',
          suggestionName: 'Tất cả NCC',
        };
        this.optionsProvider.unshift(noneProvider);
        this.filteredOptionsProvider = [...this.optionsProvider];
      });
    },
    addStockSlip: function (item) {
      this.$router.push({
        name: 'add-stock',
        query: { purchaseOrderId: item.id },
      });
    },
    addExportStockSlip: function (item) {
      this.$router.push({
        name: 'add-export-stock',
        query: { purchaseOrderId: item.id },
      });
    },
    checkCreateStockSlip(item) {
      if (item.relationType === PO_RELATION_TYPE.PARENT) {
        return false;
      }
      if (
        item.status === PURCHASE_ORDER_STATUS.ACCOUNTANT &&
        item.totalProductQuantity > item.totalImportQuantity &&
        item.relationType !== PO_RELATION_TYPE.PARENT
      ) {
        return true;
      }

      return false;
    },
    checkCancel(item) {
      if (item.relationType === PO_RELATION_TYPE.PARENT) {
        return false;
      }
      if (
        item.status === PURCHASE_ORDER_STATUS.PURCHASE &&
        item.totalImportQuantity === 0
      ) {
        return true;
      }

      return false;
    },
    checkEdit(item) {
      if (item.relationType === PO_RELATION_TYPE.CHILD) {
        return false;
      }
      const statusEditable = [
        PURCHASE_ORDER_STATUS.NEW,
        PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL,
        PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL,
      ];
      if (statusEditable.includes(item.status)) {
        return true;
      }

      return false;
    },
    reportClick: async function () {
      const params = this.setParamFilter();

      const url = `${BASE_URL}purchase-order/export-excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    onSelectedEmployee(option) {
      this.selectedEmployeeId = option.item.id;
      this.searchEmployee = option.item.name;
    },
    onSearchEmployeeChange(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredEmployeeOptions = [...filteredData];
    },
    fetchEmployee: async function () {
      await ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        this.optionsEmployee = employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.name);
          return {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredEmployeeOptions = [...this.optionsEmployee];
      });
    },
    exportExcel: async function (purchaseOrderId) {
      const url = `${BASE_URL}purchase-order/export-excel-detail/${purchaseOrderId}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    copyItem: function (item) {
      this.$router.push({
        name: 'info-purchase-orders',
        query: { id: item.id, isCreate: true },
      });
    },
    fetchCompany: async function () {
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Chọn doanh nghiệp',
        });
      });
    },
    checkRelationTypeOfPO(item) {
      switch (item.relationType) {
        case PO_RELATION_TYPE.INDEPENDENT:
          return 'mdi-star';
        case PO_RELATION_TYPE.PARENT:
          return 'fa-sitemap';
        case PO_RELATION_TYPE.CHILD:
          return 'fa-clipboard';
        default:
          return '';
      }
    },
    onClickCreate(namePath) {
      this.$router.push({
        name: `${namePath}`,
      });
    },
    onShowPOInfoModal(code) {
      this.$refs['purchase-order-info-modal'].showModal(code);
    },
    isCanAccountantApprove(rowItem) {
      return (
        rowItem.status === PURCHASE_ORDER_STATUS.ACCOUNTANT &&
        rowItem.relationType !== PO_RELATION_TYPE.CHILD &&
        this.checkPermission('PO_ACCOUNT_APPROVE')
      );
    },
    isCanConfirmPO(rowItem) {
      return (
        rowItem.status === PURCHASE_ORDER_STATUS.PURCHASE &&
        rowItem.relationType !== PO_RELATION_TYPE.CHILD &&
        this.checkPermission('PO_ACCOUNT_APPROVE')
      );
    },
    isCanApprovePO(rowItem) {
      return (
        rowItem.status === PURCHASE_ORDER_STATUS.READY &&
        rowItem.relationType !== PO_RELATION_TYPE.CHILD &&
        this.checkPermission('PO_PURCHASE_APPROVE')
      );
    },
    isCanRequestApprovePO(rowItem) {
      const conditionResult =
        rowItem.status === PURCHASE_ORDER_STATUS.NEW &&
        rowItem.relationType !== PO_RELATION_TYPE.CHILD;
      if (rowItem.sourceType === PURCHASE_ORDER_SOURCE.STORE) {
        return conditionResult && this.checkPermission('PO_STORE_APPROVE');
      }
      return conditionResult;
    },
    setParamFilter() {
      if (!this.searchProvider || this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      if (!this.searchStore || this.searchStore.trim() === '') {
        this.selectedStoreId = null;
      }

      if (!this.searchEmployee || this.searchEmployee.trim() === '') {
        this.selectedEmployeeId = null;
      }
      const params = {
        description: this.search ? this.search.trim() : null,
        code: this.searchId ? this.searchId.trim() : null,
        status: this.selectedStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        storeId: this.selectedStoreId,
        storeName: this.searchStore,
        providerId: this.selectedProvider,
        productInfo: this.searchProduct ? this.searchProduct.trim() : null,
        type: this.selectedType,
        createdById: this.selectedEmployeeId,
        importStatus: this.selectedImportStatus,
        companyId: this.selectedCompany,
        relationType: this.selectedRelationType
          ? [this.selectedRelationType]
          : null,
        sourceType: this.selectedSourceType || null,
        productId: this.selectedProductId || null,
      };
      return omitBy(params, isNull);
    },
    getParamFilter() {
      const queryParam = this.$route.query
        ? omitBy(this.$route.query, 'page')
        : null;
      if (!queryParam) {
        return;
      }
      if (queryParam.description) {
        this.search = queryParam.description;
      }
      if (queryParam.code) {
        this.searchId = queryParam.code;
      }
      if (queryParam.status) {
        this.selectedStatus = Number(queryParam.status);
      }
      if (queryParam.storeId) {
        this.selectedStoreId = Number(queryParam.storeId);
        const storeFound = this.optionsStore.find(
          (store) => store.id === this.selectedStoreId,
        );
        this.searchStore = storeFound ? storeFound.name : '';
      }
      if (queryParam.type) {
        this.selectedType = Number(queryParam.type);
      }
      if (queryParam.importStatus) {
        this.selectedImportStatus = Number(queryParam.importStatus);
      }
      if (queryParam.createdById) {
        this.selectedEmployeeId = Number(queryParam.createdById);
        const employee = this.optionsEmployee.find(
          (emp) => emp.id === this.selectedEmployeeId,
        );
        this.searchEmployee = employee ? employee.name : '';
      }
      if (queryParam.companyId) {
        this.selectedCompany = Number(queryParam.companyId);
      }
      if (queryParam.relationType) {
        this.selectedRelationType = Number(queryParam.relationType);
      }
      if (queryParam.productInfo) {
        this.searchProduct = queryParam.productInfo;
      }
      if (queryParam.providerId) {
        this.selectedProvider = queryParam.providerId;
        const providerFound = this.optionsProvider.find(
          (provider) => provider.id === this.selectedProvider,
        );
        this.searchProvider = providerFound ? providerFound.name : '';
      }
      if (queryParam.startDate) {
        this.dpForm.startDate = queryParam.startDate;
      }
      if (queryParam.endDate) {
        this.dpForm.endDate = queryParam.endDate;
      }
    },
    async fetchStoreByUser() {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.selectedStoreId = stores[0].id;
            this.searchStore = stores[0].name;
            this.disableStore = true;
          }
          this.optionsStore = stores.map((element) => {
            return {
              id: element.id,
              name: element.name,
              shortName: element.shortName,
              suggestionName: element.shortName + ' ( ' + element.name + ')',
            };
          });
          this.filteredOptionStore = [...this.optionsStore];
        }
      });
    },
    getSourceTypeByJobTitle() {
      const { jobTitleId } = getEmployeeFromLocal();
      const listShopJobTitleId = JOB_TITLE_SHOW_BY_OWNER.concat(
        JOB_TITLE_EMLOYEE_STORE,
      ).concat([JOB_TITLE.ASM, JOB_TITLE.RSM]);
      if (listShopJobTitleId.includes(jobTitleId)) {
        this.selectedSourceType = PURCHASE_ORDER_SOURCE.STORE;
        this.disableSourceType = true;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: 'purchase-orders' },
      { title: 'Danh sách phiếu yêu cầu nhập hàng' },
    ]);
  },
  async created() {
    this.getSourceTypeByJobTitle();
    await Promise.all([
      this.fetchStoreByUser(),
      this.fetchProvider(),
      this.fetchEmployee(),
    ]);
    this.fetchCompany();
    this.getParamFilter();
    this.listType.unshift({
      id: null,
      name: 'Tất cả hình thức thanh toán',
    });
    this.fetchData();
    //handle view PO by store if no view all permission
    if (!this.checkPermission('PO_VIEW_ALL')) {
      const storeStatusViewable = [PURCHASE_ORDER_STATUS.PURCHASE, PURCHASE_ORDER_STATUS.ACCOUNTANT]
      this.listStatus = this.listStatus.filter(item => storeStatusViewable.includes(item.id))
      this.selectedStatus = PURCHASE_ORDER_STATUS.PURCHASE
    }
  },
  computed: {
    selectedPurchaseOrders() {
      return this.listPurchaseOrder.filter((element) => {
        return element.selected;
      });
    },
  },
  components: {
    KTCodePreview,
    POPushModal,
    VclTable,
    datePicker,
    CancelPO,
    POInfo,
    Autosuggest,
  },
};
</script>

<style scoped>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.productCode:hover {
  text-decoration: underline;
}
.search-col {
  display: flex;
  align-items: center;
}
.append {
  font-size: 0.8em;
  margin-top: 3px;
  color: #646c7c;
}
.po-head {
  font-size: 1em;
  margin-top: 3px;
  color: #5d7cb9;
}
</style>
<style lang="scss" scoped>
.list-purchase-order-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
  .dropdown-item {
    padding: 0px;
  }
  .text-bold {
    font-weight: 600;
    margin-bottom: unset;
    font-size: 12px;
  }
  .text-date-po {
    font-size: 10px;
    line-height: 1.2rem;
    margin-bottom: 0px;
  }
}
</style>
<style lang="scss" scoped>
.nav {
  .nav-item {
    .nav-link.active {
      color: #3699ff;
    }
    .nav-link {
      color: #3f4254;
      font-weight: bold;
    }
  }
}
</style>